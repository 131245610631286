import Button from '../components/ui/Button'
import React, {useReducer} from "react";
import {Box} from 'rebass'
import Layout from "../components/Layout";
import {AddShopForm} from "../components/Forms/AddShopForm";
import {ShopsList} from "../components/ShopsList";

export default () => {
  const [isFormVisible, toggleForm] = useReducer(visible=>!visible, false)

  return <Layout>
    <Box mx={'auto'}>
    {
      !isFormVisible
        ? <ShowFormButton onClick={toggleForm}/>
        : <AddShopForm toggleForm={toggleForm}/>
    }
    </Box>

    <ShopsList/>
  </Layout>
}


const ShowFormButton = ({onClick}) => <Button
  onClick={onClick}
  mx={'auto'}
  py={30}
  sx={{
    width: '100%',
    borderStyle: 'dashed'
  }}>
  + Dodaj sklep
</Button>

