import React, {useEffect, useState} from "react";
import {Flex, Box, Text} from "rebass";
import { Label, Input } from '@rebass/forms'
import Button from '../ui/Button'
import {IoMdClose as CloseIcon} from 'react-icons/io'
import {useAuth} from "react-use-auth";
import {useMutation} from "react-apollo-hooks";
import gql from 'graphql-tag'
import fromEntries from 'object.fromentries'

const inputs = [
  {
    name: 'fullName',
    label: 'Nazwa sklepu',
    type: 'text',
    required: true
  },
  {
    name: 'websiteUrl',
    label: 'Adres strony internetowej sklepu',
    type: 'text',
    required: true
  },
  {
    name: 'email',
    label: 'Adres email',
    type: 'text',
    required: false
  },
  {
    name: 'facebook',
    label: 'Adres www profilu na facebooku',
    type: 'text',
    required: false
  },
  {
    name: 'instagram',
    label: 'Adres profilu na instagramie',
    type: 'text',
    required: false
  },
  {
    name: 'address',
    label: 'Adres',
    type: 'text',
    required: false
  },
  {
    name: 'description',
    label: 'Opis',
    type: 'text',
    required: false
  },
]

const initialState = fromEntries(inputs.map(i => [i.name, '']))

export const AddShopForm = ({
  toggleForm
}) => {
  const {isAuthenticated, userId} = useAuth()
  const [state, setState] = useState(initialState)
  const validateForm = () => {
    const errors = inputs
      .filter(i => i.required && !state[i.name])
      .map(i => i.name)

    console.log(errors)

    if (!errors.length) {
      return true
    }

    alert("Uzupełnij pole: " + inputs.find(i => i.name === errors[0]).label)
    return false
  }

  const [updateShop, {data}] = useMutation(
    gql`
        mutation updateShop($input: ShopInput!) {
            updateShop(input: $input) {
                id
            }
        }
    `, {
      variables: {
        input: {
          createdBy: userId,
          ...state
        }
      }
    }
  )

  return <Flex flexDirection={'column'}>
    <Flex>
      <Box mx={'auto'}/>
      <CloseIcon onClick={toggleForm}/>
    </Flex>
    {
      isAuthenticated()
        ? <>
        {inputs.map(input => <Box as={'form'}>
          <Label>{input.label}</Label>
          <Input
            required={input.required}
            type={input.type}
            onChange={e=>{
              const toSet = {
                [input.name]: e.target.value
              }
              setState(
                prevState => ({
                  ...prevState,
                  ...toSet
                  // why that's not working??
                  // [input.name]: e.target.value
                })
              )
            }}
          />
        </Box>)}
        <Button onClick={()=>validateForm() && updateShop()} type={'submit'}>
          Submit
        </Button>
        </>
        : <Flex>
            <Text
              mx={'auto'}
            fontSize={[ 3, 4, 5 ]}
            fontWeight='bold'
            color='primary'
          >
            Musisz się zalogować żeby dodać nowy sklep
          </Text>
        </Flex>
    }
  </Flex>;
}

// TODO
// pdoducts
// opinions
// math with physical shops
// allow adding physical shops
