import React from 'react'
import {Flex, Box} from 'rebass'
import {useQuery} from "react-apollo-hooks";
import styled from 'styled-components'
import {graphql, useStaticQuery} from "gatsby";
import {PacmanLoader} from "react-spinners"
import gql from 'graphql-tag'

export const ShopsList = () => {
  // prepopulate on server
  const data = useStaticQuery(graphql`
    query {
        hempmarket_api {
            allShops {
                id
                fullName
                websiteUrl
                facebook
                instagram
                email
                address
                description
            }
        }
    }
  `)

  // this query is used to fetch live data
  const apolloData = useQuery(gql`
      query {
          allShops {
              id
              fullName
              websiteUrl
              facebook
              instagram
              email
              address
              description
          }
      }
  `)

  const elements = apolloData.data && apolloData.data.allShops || data.hempmarket_api && data.hempmarket_api.allShops || []

  return <>
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)'
    }}>
      <Box sx={{fontWeight: 'bold'}}>Nazwa</Box>
      <Box sx={{fontWeight: 'bold'}}>WWW</Box>
      <Box sx={{fontWeight: 'bold'}}>FB</Box>
      <Box sx={{fontWeight: 'bold'}}>INSTA</Box>

      {elements.map(element => <>
        <Box>{element.fullName}</Box>
        <Box>{element.websiteUrl}</Box>
        <Box>{element.facebook}</Box>
        <Box>{element.instagram}</Box>
      </>)}
    </Box>

    <Flex mx={'auto'} alignItems={'center'} justifyContent={'center'}>
      {apolloData.loading && <PacmanLoader type={'pacman'} color={'gray'}/>}
    </Flex>
  </>
}

